<template>
  <button
    :class="{
      'h-12 w-12': size === 'sm',
      'h-16 w-16': size === 'md',
      'h-20 w-20': size === 'base',
      'h-24 w-24': size === 'lg',
    }"
    :title="label"
    class="ease-out-expo flex items-center justify-center rounded bg-white text-gray-700 transition-colors duration-700 hover:text-gray-900"
    @click="onClick($event)"
  >
    <span class="sr-only">{{ label }}</span>
    <UiIcon :icon="icon" :size="size" />
  </button>
</template>

<script setup lang="ts">
import type { PickIcon } from './UiIcon.vue'

defineProps<{
  label: string
  icon: PickIcon<'arrow-left' | 'arrow-right' | 'play' | 'fullscreen'>
  size: 'sm' | 'md' | 'base' | 'lg'
}>()

const emit = defineEmits<{
  click: [payload: Event]
}>()

function onClick(event: Event): void {
  emit('click', event)
}
</script>
